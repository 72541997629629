import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getPager} from "../../helpers/common";
import {
    API_HOME_PACKAGE_TYPES,
    API_SEARCH_ONLY_LISTING,
} from "../../services/ApisRoutes";
import {get, post} from "../../services/HttpClient";
import {toast} from "react-toastify";

export const getHotels = createAsyncThunk(
    "hotels/getHotels",
    async (params) => {
        return new Promise((resolve, reject) => {
            post(API_SEARCH_ONLY_LISTING, params)
                .then((response) => {
                    // eslint-disable-next-line array-callback-return
                    let _offers = response?.data?.offers?.map((hotel) => {
                        if (hotel !== undefined) {
                            return {
                                ...hotel?.newPackagedOffer?.hotelOfferSingleRoom,
                                price: {
                                    totalPrice: hotel?.price?.packageTotalPrice?.value,
                                    currency: hotel?.price?.packageTotalPrice?.currency,
                                },
                                perPersonPrice: {
                                    totalPrice: hotel?.price?.averageCostPerPerson?.value,
                                    currency: hotel?.price?.averageCostPerPerson?.currency,
                                },
                                address:
                                    hotel?.newPackagedOffer?.hotelOfferSingleRoom?.location
                                        ?.address?.city +
                                    ", " +
                                    hotel?.newPackagedOffer?.hotelOfferSingleRoom?.location
                                        ?.address?.country,
                                objHotel: hotel,
                                guidForBooking: response?.data?.correlationId
                                    ? response.data.correlationId
                                    : params.GUID,
                            };
                        }
                    });

                    if (response?.data?.warnings?.length) {
                        toast.info(response?.data?.warnings[0].description);
                    }

                    return resolve({
                        offers: _offers,
                        correlationId: response?.data?.correlationId || params?.GUID,
                        totalOffersCount:
                            response?.data?.metaData?.offersAfterFilter ||
                            _offers?.length ||
                            0,
                        params: params,
                        response: response,
                    });
                })
                .catch((error) => {
                    console.log(JSON.stringify(error));
                    if (error?.response?.data?.errors?.length) {
                        toast.error(error?.response?.data?.errors[0]?.description);
                    }
                    return reject(error);
                });
        });
    }
);

export const getPackages = createAsyncThunk("hotels/getPackages", async () => {
    const response = await get(API_HOME_PACKAGE_TYPES);
    return response.data?.packageTypes;
});

const initialState = {
    offers: [],
    offersContainer: [],
    packageTypes: [],
    loadingScreen: false,
    favPkgs: [],
    selectedCurrency: "USD",
    pagination: null,
    correlationId: null,
    shouldRedirect: false,
    showRectangular: false,
    modals: {login: false, register: false, confirmation: false},
    responseType: null,
    error: null,
    isPackageOffers: true,
    lowPrice: 0,
    highPrice: 2500,
    metaDataCount: 0,
    transaction_id: null,
    branchData: null,
};

export const appconfigSlice = createSlice({
    name: "appconfig",
    initialState,
    reducers: {
        setActivityIndicator: (state, action) => {
            state.loadingScreen = action.payload;
        },
        setOffers: (state, action) => {
            state.offers = action.payload;
        },
        setDetailsServerState: (state, action) => {
            state.details = action.payload;
        },
        setPackageTypesState: (state, action) => {
            state.packageTypes = action.payload;
        },
        setSearchOnlyListingRequestParams: (state, action) => {
            state.searchOnlyListingRequestParams = action.payload;
        },
        setFlightSegmentList: (state, action) => {
            state.flightSegmentList = action.payload;
        },
        setPassengerAgeRoomItemList: (state, action) => {
            state.passengerAgeRoomItemList = action.payload;
        },
        setFavPkgs: (state, action) => {
            state.favPkgs = action.payload;
        },
        setLowPrice: (state, action) => {
            state.lowPrice = action.payload;
        },
        setHighPrice: (state, action) => {
            state.highPrice = action.payload;
        },
        setLowHighPrice: (state, action) => {
            state.lowPrice = action.payload.lowValue;
            state.highPrice = action.payload.highValue;
        },
        setFiltersList: (state, action) => {
            state.filtersList = action.payload;
        },
        filteredHotels: (state, action) => {
            state.offers = state.offersContainer.filter((hotel) => {
                return (
                    hotel?.price?.totalPrice >= action.payload.lowValue &&
                    hotel?.price?.totalPrice <= action.payload.highValue
                );
            });
        },
        setCurrency: (state, action) => {
            state.selectedCurrency = action.payload;
        },
        removeOffer: (state, action) => {
            state.favPkgs = state.favPkgs.filter(
                (item) => item.id !== action.payload
            );
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        setPage: (state, action) => {
            state.page = action.payload;
        },
        setShouldRedirect: (state, action) => {
            state.shouldRedirect = action.payload;
        },
        setShowRectangular: (state, action) => {
            state.showRectangular = action.payload;
        },
        setModals: (state, action) => {
            state.modals = action.payload;
        },
        setError: (state) => {
            state.error = "";
        },
        setMetaDataCount: (state, action) => {
            state.metaDataCount = action.payload;
        },
        setResponseType: (state, action) => {
            state.responseType = action.payload;
        },
        setTransactionId: (state, action) => {
            state.transaction_id = action.payload;
        },
        setBranchData: (state, action) => {
            state.branchData = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getHotels.pending, (state, action) => {
                if (action?.meta?.arg?.shouldLoading === false) {
                    return;
                }
                state.loadingScreen = true;
            })
            .addCase(getHotels.fulfilled, (state, action) => {
                let {offers, correlationId, totalOffersCount, params, response} =
                    action.payload;
                state.correlationId = correlationId;
                state.offers = offers;
                state.offersContainer = offers;
                state.pagination = getPager(totalOffersCount, params.Page, 9, window.innerWidth < 768 ? 5 : 10);
                if (offers?.length) {
                    state.responseType = response?.data?.responseType;
                }

                const arr = offers?.map((m) => Number(m?.perPersonPrice?.totalPrice));

                state.lowPrice = arr.sort()[0];
                state.highPrice = arr.sort()[arr.length - 1];

                const packages = [...state.packageTypes];
                const index = packages?.findIndex((f) => f.guid === correlationId);
                state.isPackageOffers = index !== -1;

                state.loadingScreen =
                    response?.data?.responseType === "RealTime" && offers.length > 0;

                if (response?.data?.metaData?.offersAfterFilter) {
                    state.metaDataCount = response?.data?.metaData?.offersAfterFilter;
                } else {
                    state.metaDataCount = response?.data?.offer?.length;
                }
            })
            .addCase(getHotels.rejected, (state) => {
                state.loadingScreen = false;
            })
            .addCase(getPackages.pending, (state) => {
                state.loadingScreen = true;
            })
            .addCase(getPackages.fulfilled, (state, action) => {
                state.loadingScreen = false;
                state.packageTypes = action.payload;
            })
            .addCase(getPackages.rejected, (state) => {
                state.loadingScreen = false;
            });
    },
});

// This will return complete state of this slice.
export const getAppConfig = (state) => state.appconfig;

// This will sort offer on prices and return first 4 offers.
export const getCheapestOffers = (state) => {
    return (
        state.appconfig.offers
            ?.slice()
            ?.sort((a, b) => a?.price?.totalPrice - b?.price?.totalPrice)
            .slice(0, 4) || []
    );
};

// Action creators are generated for each case reducer function
export const {
    setPackageTypesState,
    setOffers,
    setActivityIndicator,
    removeOffer,
    setPagination,
    setShowRectangular,
    setModals,
    setError,
    setResponseType,
    setTransactionId,
    setBranchData,
} = appconfigSlice.actions;

export default appconfigSlice.reducer;
