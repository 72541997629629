
import { createSlice } from '@reduxjs/toolkit';
import AppConfig from "../../helpers/config";

const initialState = {
  locale: AppConfig.appLanguages[1],
  currency: AppConfig.currencies[0],
  darkMode: true,
  loginUser: {},
  isLogin: false,
  mainAirport: {},
  otherAirport: [],
  airports: [],
};

export const loginconfigSlice = createSlice({
  name: 'loginconfig',
  initialState,
  reducers: {
    changeAppLocale: (state, action) => {
      state.locale = action.payload;
    },
    changeAppCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
    setLoginUser: (state, action) => {
      state.loginUser = action.payload;
    },
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    setMainAirport: (state, action) => {
      state.mainAirport = action.payload;
    },
    setOtherAirport: (state, action) => {
      state.otherAirport = action.payload;
    },
    setAirports: (state, action) => {
      state.airports = action.payload;
    },
  },
});

// This will return complete state of this slice.
export const getLoginConfig = (state) => state.loginconfig;

// Action creators are generated for each case reducer function
export const {
  changeAppLocale,
  changeAppCurrency,
  setDarkMode,
  setLoginUser,
  setIsLogin,
  setMainAirport,
  setOtherAirport,
  setAirports,
} = loginconfigSlice.actions;

export default loginconfigSlice.reducer;
