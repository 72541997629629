import React, {useEffect, useState} from "react";
import AppHeader from "../../components/header";
import Footer from "../../components/footer";
import "./style.scss";
import {useDispatch, useSelector} from "react-redux";
import {changeAppLocale, getLoginConfig} from "../../redux/slices/loginconfigSlice";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import AppConfig from "../../helpers/config";

const PrivacyPolicy = () => {
    const url = window.location.href;
    const { t } = useTranslation();
    const objUrl = new URL(url);
    const searchParams = new URLSearchParams(objUrl.search);
    const urlLocale = searchParams.get("locale");
    const fromExternal = searchParams.get("external");
    const systemLanguage = navigator?.language;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginConfig = useSelector(getLoginConfig);
    const [justCame, setJustCame] = useState(true);
    const [isDutch, setIsDutch] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    };

    useEffect(() => {
        const changeLocale = (isDutch) => {
            dispatch(changeAppLocale({
                id: isDutch ? 0 : 1,
                country: isDutch ? "Nederlands" : "Engels",
                code: isDutch ? "nl_NL" : "en_US",
                name: isDutch ? "Dutch" : "English",
                short: isDutch ? "Nl" : "En",
                currency: isDutch
                    ? {code: "EUR", name: "Euro", symbol: "€"}
                    : {code: "USD", name: "Dollar", symbol: "$"},
            }));
            setIsDutch(isDutch);
        };

        const localeIncludesDutch = fromExternal === 'true' ? systemLanguage?.includes('nl') : urlLocale?.includes('nl');
        changeLocale(localeIncludesDutch);
    }, []);

    useEffect(() => {
        scrollToTop();
        if (!justCame) {
            navigate(`/privacy-policy/query?locale=${loginConfig?.locale?.code}&external=${false}`, {replace: true});
            const language = AppConfig.appLanguages.find(f => f.code === loginConfig?.locale?.code);
            dispatch(changeAppLocale(language));
            setIsDutch(language?.code === 'nl_NL');
        } else {
            setJustCame(false);
        }
    }, [loginConfig?.locale?.code]);

    return (
        <div className="static-page privacy-policy">
            <div className="main-wrapper">
                <div className="container">
                    <AppHeader/>
                    {!isDutch ? (
                        <main className="body-wrapper">
                            <div className="left-action-back-btn">
                                <a onClick={() => navigate(-1)}>{t("Buttons.Back")}</a>
                            </div>
                            <h2 className="text-center">PRIVACY POLICY</h2>
                            <p>
                                Genius® (App) and Genius.Travel (site) are part of GeniusTravel® B.V. The registered
                                office of GeniusTravel B.V. is in the Netherlands. GeniusTravel B.V. acts as the
                                controller of your personal data collected on the Genius.Travel website
                                (the &apos;Website&apos;) and the associated mobile
                                application &apos;Genius&apos; (the &apos;Applications&apos;). GeniusTravel B.V. makes
                                every effort to protect your privacy as best as possible when you visit the Website or
                                use the Applications. We naturally strive to provide the best possible user experience.
                                By using the app &apos;Genius&apos; or the site Genius.Travel, you accept our privacy
                                policy and the privacy policy of Expedia where the booking will be completed.
                            </p>
                            <h5>What data do we collect?</h5>
                            <p>
                                We register the personal data you provide us with when you register. GeniusTravel B.V.
                                only collects the personal information with which you have agreed to communicate with
                                you. This personal information includes in particular your name, email address and
                                password (so that you can retrieve it if you happen to forget it), as well as
                                information about you and your passengers. When you browse the Website or the
                                Applications, GeniusTravel B.V. can collect information linked to your browser session.
                                The following browsing data on the Website or the Applications is collected: pages
                                visited, searches performed, opening newsletters, clicking on offers. When you browse
                                the Website or the Applications, GeniusTravel B.V. links your devices to a unique ID and
                                collects the information linked to the browser or, in the case of using the app, the
                                operating system you use (Apple or Android) and its version. Finally, GeniusTravel B.V.
                                uses cookies to store and consult information (see below).
                            </p>
                            <h5>What do you do with my data?</h5>
                            <p>
                                The above data is mainly intended to improve the user experience so that we can improve
                                our website and applications for you. As soon as you book a trip, you are on the website
                                or application of our partner Expedia. Expedia communicates your collected personal data
                                with hotels, airlines, tour operators, travel guides, etc.). This is of course necessary
                                to be able to travel. This data is also processed to manage your customer relationship
                                and for Expedia&apos;s accounting requirements (for example your invoices).
                            </p>
                            <p>
                                If you give an opt-in to GeniusTravel B.V. you will possibly be informed about new
                                offers, information about cities to visit, etc. An unsubscribe link is present on all
                                communications that are sent and gives you the freedom to stop receiving emails at any
                                time. After booking through our partner Expedia, they can send you different types of
                                emails:
                            </p>
                            <ul>
                                <li>
                                    Emails related to your order: confirmation of stay, sending travel documents,
                                    satisfaction survey.
                                </li>
                                <li>
                                    Emails and SMS: welcome, information, linked to your level of activity or inactivity
                                </li>
                                <li>
                                    Emails about scheduled alerts: use the information that you are allowed to enter on
                                    the Website and the Applications, and according to the alerts that you may want to
                                    schedule from your account.
                                </li>
                            </ul>
                            <h5>What is a cookie?</h5>
                            <p>
                                A cookie is a piece of information that is stored on a computer, tablet or mobile when
                                you surf the internet. Cookies allow us to recognize your browser. They are mainly used
                                to store your choices and preferences. They do not damage your device. They make it
                                possible to personalize the content that you view, it is also in your own interest.
                            </p>
                            <h5>How does this work at GeniusTravel B.V.?</h5>
                            <p>
                                When you browse the Genius.Travel website, you can refuse or allow us to store your
                                preferences, for example. The data for which your consent is required and for which you
                                have given us consent are then used to show you the best offers, preferably those that
                                meet your expectations as much as possible. The information from these cookies can be
                                stored by us and by third parties for measurement tools and retargeting campaigns. NB:
                                if you choose to refuse certain cookies, you will also not receive personal offers,
                                tailored to your search behavior. Likewise, if you refuse certain advertising cookies,
                                this does not mean that you will no longer see any advertisements online. This simply
                                means that the advertisements will not be based on your preferences and user behavior
                                and will therefore be less relevant to you.
                            </p>
                            <h5>What types of cookies does GeniusTravel B.V. use?</h5>
                            <ul>
                                <li>
                                    Technical and functional cookies: these are always active because they enable the
                                    proper functioning of our website and applications and to improve our service, for
                                    example based on your preferences, and to adjust our content, whereby we hopefully
                                    meet your expectations as much as possible and show you relevant trips.
                                </li>
                                <li>
                                    Analytical cookies: the data we collect mainly includes the pages you view, open and
                                    leave, the type of browser and device you use and information such as the number of
                                    clicks on a particular page. This data remains anonymous and does not reveal your
                                    identity.
                                </li>
                                <li>
                                    Advertising or marketing cookies: these are used to display advertisements from
                                    GeniusTravel B.V. on other websites. They are built on the basis of information
                                    collected during searches and the filters used when you search for a nice trip on
                                    our Website or on our applications, and can be stored by us or by third parties for
                                    the purpose of retargeting campaigns or, for example, the newsletter.
                                </li>
                            </ul>
                            <h5>
                                How do I manage my cookie choices?
                            </h5>
                            <p>
                                You can allow or refuse certain cookies to be stored on the Website. You can also allow
                                or refuse certain cookies to be stored on your device using the settings of your
                                internet browser software. The configuration of these settings varies depending on your
                                browser. Detailed instructions can generally be found in the Help menu of your browser.
                                For the purpose of retargeting advertisements by GeniusTravel.com, we may also send some
                                of your data to partners (e.g. Facebook), so that we can, for example, help you remember
                                the fantastic trip you found through us. Or help you get inspiration for your next trip.
                                In particular, product categories that we determine based on the trips you have viewed
                                on our website or in our app can be used for retargeting (such as destination, departure
                                date, number of passengers), with a view to being as relevant to you as possible.
                            </p>
                            <p>
                                Statistical analyses: The data we collect is used anonymously to perform statistical
                                analyses that enable us to improve the website or the applications.
                            </p>
                            <h5>How is my data secured?</h5>
                            <p>
                                GeniusTravel B.V. implements all technical and organizational measures at its disposal
                                that are necessary to protect the personal data that we have. We therefore have
                                implemented the following security measures:
                            </p>
                            <ul>
                                <li>
                                    Our internal IT network, our servers, the Website and the applications are subject
                                    to technical measures that enable us to properly secure your personal data.
                                </li>
                                <li>
                                    Your personal data that you have provided to us will only be accessible to employees
                                    and subcontractors of GeniusTravel B.V., only the data that is strictly necessary
                                    for the processing and to which you have consented.
                                </li>
                                <li>
                                    Any illegal intrusion into our database will give rise to an immediate warning and
                                    implementation of the necessary actions.
                                </li>
                                <li>
                                    We require all our partners to whom your personal data has been transferred to
                                    guarantee at least a certain level of security equal to that used within
                                    GeniusTravel B.V.
                                </li>
                            </ul>
                            <h5>
                                Can I ask you to delete my account?
                            </h5>
                            <p>
                                In accordance with the General Data Protection Regulation No. 2016/679, as transposed
                                into national law, you have the right to rectify or delete your data, this can be done
                                by sending an e-mail to:{" "}
                                <a style={{color: '#0d6efd'}} href="mailto:feedback@geniustravel.com" target="blank">
                                    feedback@geniustravel.com
                                </a>
                            </p>
                            <p>
                                We hope that you will enjoy using the app ‘Genius’ or the website Genius.Travel and we
                                are happy to help you find the best holiday at the best possible price, based upon your
                                personal preferences.
                            </p>
                        </main>
                    ) : (
                        <main className="body-wrapper">
                            <h2 className="text-center">PRIVACYBELEID</h2>
                            <p>
                                Genius® (App) en Genius.Travel (site) zijn onderdeel van GeniusTravel® B.V. De
                                vestigingsplaats Van GeniusTravel B.V. is in Nederland. GeniusTravel B.V. treedt op als
                                verwerkingsverantwoordelijke van uw persoonlijke gegevens verzameld op de
                                Genius.Travel-website (de &apos;Website&apos;) en de bijbehorende mobiele
                                applicatie &apos;Genius&apos; (de
                                &apos;Applicaties&apos;). GeniusTravel B.V. doet alles om uw privacy zo goed mogelijk te
                                beschermen wanneer u de Website bezoekt of de Applicaties gebruikt. Wij streven
                                uiteraard naar een zo prettig mogelijke gebruikerservaring. Door de app ‘Genius’ of de
                                site Genius.Travel te gebruiken, accepteert u ons privacybeleid en het privacybeleid van
                                Expedia waar de boeking zal worden voltooid.
                            </p>
                            <h5>
                                Welke gegevens verzamelen we?
                            </h5>
                            <p>
                                De persoonlijke gegevens die u ons geeft wanneer u zich registreert, die registeren wij.
                                GeniusTravel B.V. verzamelt alleen de persoonlijke informatie waarmee u heeft ingestemd
                                om met u te communiceren. Deze persoonlijke informatie omvat met name uw naam,
                                e-mailadres en wachtwoord (zodat u dit eventueel opnieuw kunt opvragen mocht u het zelf
                                vergeten zijn), evenals informatie over u en uw eventuele passagiers. Wanneer u door de
                                Website of de Applicaties bladert kan GeniusTravel B.V. informatie verzamelen gekoppeld
                                aan uw browsersessie. De volgende browsegegevens op de Website of de Applicaties wordt
                                verzameld: bezochte pagina&apos;s, uitgevoerde zoekopdrachten, openen van nieuwsbrieven,
                                klikken op aanbiedingen. Wanneer u door de Website of de Applicaties bladert koppelt
                                GeniusTravel B.V. uw apparaten aan een unieke ID en verzamelt de informatie gekoppeld
                                aan de browser of in het geval van het gebruik van de app, het besturingssysteem dat u
                                gebruikt (Apple of Android) en de versie ervan. Tenslotte gebruikt GeniusTravel B.V.
                                cookies om informatie op te slaan en te raadplegen (zie hieronder).
                            </p>
                            <h5>
                                Wat doen jullie met mijn gegevens?
                            </h5>
                            <p>
                                Bovenstaande gegevens zijn met name bedoeld om de gebruikerservaring te verbeteren zodat
                                we onze website en applicaties voor u kunnen verbeteren. Zodra u een reis boekt bevindt
                                u zich op de website of applicatie van onze partner Expedia.
                            </p>
                            <p>
                                Uw verzamelde persoonsgegevens communiceert Expedia met hotels,
                                luchtvaartmaatschappijen, touroperators, reisgidsen enz.). Dit is uiteraard noodzakelijk
                                om op reis te kunnen gaan. Deze gegevens worden ook verwerkt om uw klantrelatie te
                                beheren en voor de boekhoudkundige vereisten van Expedia (bijvoorbeeld uw facturen).
                            </p>
                            <p>
                                Als u een opt-in geeft bij GeniusTravel B.V. zal u mogelijkerwijs geïnformeerd worden
                                omtrent nieuwe aanbiedingen, informatie over te bezoeken steden, etc. Een afmeldlink is
                                aanwezig op alle communicatie die wordt verzonden en geeft u de vrijheid om op elk
                                moment te stoppen met het ontvangen van e-mails. Na het boeken via onze partner Expedia
                                kunnen zij u verschillende soorten e-mails sturen:
                            </p>
                            <ul>
                                <li>
                                    E-mails met betrekking tot uw bestelling: bevestiging van verblijf, verzending
                                    reisdocumenten, tevredenheidsonderzoek.
                                </li>
                                <li>
                                    E-mails en sms: welkom, informatie, gekoppeld aan uw mate van activiteit of
                                    inactiviteit.
                                </li>
                                <li>
                                    E-mails over geplande waarschuwingen: gebruik de informatie die u mag invoeren op
                                    de Website en de Applicaties, en volgens de waarschuwingen die u mogelijk vanuit uw
                                    account wilt plannen.
                                </li>
                            </ul>
                            <h5>Wat is een cookie?</h5>
                            <p>
                                Een cookie is een stukje informatie dat wordt opgeslagen op een computer, tablet of
                                mobiel wanneer u op internet surft. Cookies stellen ons in staat stellen uw browser te
                                herkennen. Ze worden voornamelijk gebruikt om uw keuzes en voorkeuren op te slaan. Zij
                                brengen geen schade toe aan uw apparaat. Zij maken het mogelijk de inhoud te
                                personaliseren die u bekijkt, het is ook in uw eigen belang.
                            </p>
                            <h5>
                                Hoe werkt dit bij GeniusTravel B.V.?
                            </h5>
                            <p>
                                Wanneer u door de Genius.Travel-website bladert, kunt u weigeren of ons in staat stellen
                                om bijvoorbeeld uw voorkeuren op te slaan. De data waarvoor uw toestemming is vereist en
                                waarvoor u ons toestemming hebt gegeven worden vervolgens gebruikt om u de beste
                                aanbiedingen te laten zien, die het liefst zoveel als mogelijk aan uw verwachtingen
                                voldoen. De informatie uit deze cookies kan door ons en door derden worden opgeslagen
                                voor meetinstrumenten en retargeting campagnes. NB: als u ervoor kiest om bepaalde
                                cookies te weigeren, krijgt u ook geen persoonlijke aanbiedingen te zijn, afgestemd op
                                uw zoekgedrag. Evenzo, als u bepaalde advertentiecookies weigert, betekent dit niet dat
                                u niets meer online zult zien aan advertenties. Dit betekent simpelweg dat de
                                advertenties niet gebaseerd zullen zijn op uw voorkeuren en uw gebruikersgedrag en deze
                                dus minder relevant voor u zullen zijn.
                            </p>
                            <h5>
                                Welke soorten cookies gebruikt GeniusTravel B.V.?
                            </h5>
                            <ul>
                                <li>
                                    Technische en functionele cookies: deze zijn altijd actief omdat ze de goede
                                    werking van onze website en applicaties mogelijk maken en onze service, bijvoorbeeld
                                    gebaseerd op uw voorkeuren, te verbeteren en onze inhoud aan te passen, waarbij we
                                    hopelijk zoveel mogelijk aan uw verwachtingen voldoen en relevante reizen aan u
                                    tonen.
                                </li>
                                <li>
                                    Analytische cookies: de gegevens die we verzamelen omvatten voornamelijk de
                                    pagina&apos;s die u bekijkt, opent en verlaat, het type browser en apparaat dat u
                                    gebruikt en informatie zoals het aantal klikken op een bepaalde pagina. Deze
                                    gegevens blijven uiteraard anoniem en geven uw identiteit niet prijs.
                                </li>
                                <li>
                                    Advertentie- of marketingcookies: deze worden gebruikt voor weergave van
                                    advertenties van GeniusTravel B.V. op andere websites. Ze zijn gebouwd op basis van
                                    tijdens het zoeken verzamelde informatie en de gebruikte filters wanneer u zoekt
                                    naar een leuke reis op onze Website of op onze applicaties, en kan door ons of door
                                    derden worden opgeslagen ten behoeve van retargeting campagnes of bijvoorbeeld de
                                    nieuwsbrief.
                                </li>
                            </ul>
                            <h5>
                                Hoe beheer ik mijn cookiekeuzes?
                            </h5>
                            <p>
                                U kunt toestaan of weigeren dat bepaalde cookies worden opgeslagen op de Website. U kan
                                ook toestaan of weigeren dat bepaalde cookies op uw apparaat worden opgeslagen met
                                behulp van de instellingen van uw internetbrowsersoftware. De configuratie van deze
                                instellingen varieert afhankelijk van uw browser.
                            </p>
                            <p>Gedetailleerde instructies vindt u over het algemeen in de Help-menu van uw browser.</p>
                            <p>
                                Met het oog op retargeting van advertenties door GeniusTravel.com, kunnen we ook enkele
                                van uw gegevens naar partners sturen (bijv. Facebook), zodat we u bijvoorbeeld helpen
                                herinneren aan de fantastische reis die u via ons heeft gevonden. Of u helpen inspiratie
                                op te doen voor uw volgende reis. Met name productcategorieën die we bepalen aan de hand
                                van de reizen die u op onze website of in onze app heeft bekeken kunnen worden gebruikt
                                voor de retargeting (zoals bestemming, vertrekdatum, aantal passagiers), dit met het oog
                                dat we zo relevant mogelijk voor u zijn.
                            </p>
                            <p>
                                Statistische analyses: De gegevens die we verzamelen worden anoniem gebruikt om
                                statistische analyses uit te voeren die ons in staat stellen om de website of de
                                applicaties te verbeteren.
                            </p>
                            <h5>
                                Hoe worden mijn gegevens beveiligd?
                            </h5>
                            <p>
                                GeniusTravel B.V. voert alle tot haar beschikking zijnde technische en organisatorische
                                maatregelen uit die nodig zijn om de persoonsgegevens waarover wij beschikken te
                                beschermen. Daartoe hebben wij met name de volgende beveiligingen ingevoerd:
                            </p>
                            <ul>
                                <li>
                                    Ons interne IT-netwerk, onze servers, de Website en de applicaties zijn onderworpen
                                    aan technische maatregelen die ons in staat stellen uw persoonsgegevens goed te
                                    beveiligen.
                                </li>
                                <li>
                                    Uw persoonsgegevens die u ons heeft verstrekt zijn alleen toegankelijk voor
                                    medewerkers en onderaannemers van GeniusTravel B.V., alleen de gegevens die voor de
                                    verwerking strikt noodzakelijk zijn en waarmee u heeft ingestemd.
                                </li>
                                <li>
                                    Elke illegale inbraak in onze database geeft aanleiding tot een onmiddellijke
                                    waarschuwing en implementatie van de nodige acties.
                                </li>
                                <li>
                                    Wij eisen van al onze partners aan wie uw persoonsgegevens zijn overgedragen om op
                                    zijn minst een zekere mate van veiligheid te garanderen gelijk aan die welke binnen
                                    GeniusTravel B.V. wordt gehanteerd.
                                </li>
                            </ul>
                            <h5>
                                Kan ik jullie vragen mijn account te verwijderen?
                            </h5>
                            <p>
                                In overeenstemming met de Algemene Verordening Gegevensbescherming nr. 2016/679, zoals
                                omgezet in nationale wetgeving, hebt u het recht om rectificatie of het laten
                                verwijderen van uw gegevens, dit kan middels een e-mail naar:{" "}
                                <a style={{color: '#0d6efd'}} href="mailto:feedback@geniustravel.com"
                                   target="blank">
                                    feedback@geniustravel.com
                                </a>
                            </p>
                            <p>
                                We hopen dat u zult genieten van het gebruik van de app ‘Genius’ of de website
                                Genius.Travel en we helpen u graag om de beste vakantie voor de beste prijs te vinden,
                                op basis van uw persoonlijke voorkeuren.
                            </p>
                        </main>
                    )}
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
