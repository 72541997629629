import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import appconfigSlice from "./slices/appconfigSlice";
import loginconfigSlice from "./slices/loginconfigSlice";
import searchFilterSlice from "./slices/searchFilterSlice";
import bookingSlice from "./slices/bookingSlice";

const reducers = combineReducers({
  appconfig: appconfigSlice,
  loginconfig: loginconfigSlice,
  searchfilters: searchFilterSlice,
  booking: bookingSlice,
});

const persistConfig = {
  key: "gt-web:rootApp",
  storage,
  whitelist: ["loginconfig", "appconfig", "searchfilters"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const storeData = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    console.log('Data successfully saved');
  } catch (e) {
    console.log('Failed to save the data to localStorage', e);
  }
};

export const getData = (key) => {
  try {
    const value = localStorage.getItem(key);
    if (value !== null) {
      return JSON.parse(value);
    }
  } catch (e) {
    console.log('Failed to fetch the data from localStorage', e);
  }
  return null;
};

export const removeData = (key) => {
  try {
    localStorage.removeItem(key);
    console.log('Data removed');
  } catch (e) {
    console.log('Failed to remove the data from localStorage', e);
  }
};

export default store;
