import React, { useEffect, useState } from "react";
import FaqAccordion from "../../FaqAccordion/FaqAccordion";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { FAQ } from "../../../helpers/FAQ";
import { useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";
import { getLoginConfig } from "../../../redux/slices/loginconfigSlice";

const Faq = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loginConfig = useSelector(getLoginConfig);
  const [FAQs, setFAQs] = useState(
    FAQ.filter((value) => value.locale === loginConfig?.locale?.code)
  );
  useEffect(() => {
    setFAQs(FAQ.filter((value) => value.locale === loginConfig?.locale?.code));
  }, [loginConfig?.locale?.code]);

  return (
    <section id="faq">
      <div className="container">
        <div className="row">
          <div className="col-12">
            
            <div className="title">
                <div className="left-action-back-btn mb-3">
                  <a style={{color: "#fff"}} onClick={() => navigate(-1)}>{t("Buttons.Back")}</a>
                </div>
              {/* <div className="d-flex align-items-center"> */}
                {/* <h5 className="subtitle">FAQ</h5> */}
              <h2 className="heading heading-1 font-poppings medium">
              FAQ: {t("Faq.FindAnswers")}
              </h2>
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {FAQs.map((value, index) => (
              <FaqAccordion
                key={index}
                question={value.faqtitle}
                answer={value.faqtext}
              ></FaqAccordion>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
